import { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import LoginHeader from '../../app/home/components/LoginHeader';
import { Footer } from '../../app/home/components/Footer';


 export const SuspenseFallback = () => {
  
  
    return (
        <div className="flex flex-col min-h-screen ">
                  <LoginHeader type={true}></LoginHeader>
                    <div className="container mx-auto py-8 px-4 max-w-6xl">
                    <div className="text-2xl font-bold mb-12 text-left">
                        <div style={{
                            display: `flex`,
                            justifyContent: `center`,
                            alignItems: `center`,
                            height: `80vh`
                        }}>
                      
                        <ClipLoader size={300} color='#FFAA77'/> 
                        </div>
                    </div>
                    </div>
                  {/* <Footer/> */}
        </div>
    )
  };
 
  
