import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// 상태를 관리하는 스토어
export const useLoggingStore = create(
  persist(
    (set) => ({
      // 초기값 설정
      loggingData: {
        is_front_logging: true,
        is_img_download_logging: true,
      },
      // 상태 업데이트 함수
      setLoggingData: (newLoggingData) => set({ loggingData: newLoggingData }),
      
      // 상태 초기화 함수
      resetLoggingData: () => set({
        loggingData: {
          is_front_logging: false,
          is_img_download_logging: false,
        }
      }),
    }),
    {
      name: 'logging-data-storage', // 로컬스토리지에 저장할 키
      getStorage: () => localStorage, // 로컬스토리지 사용
    }
  )
);