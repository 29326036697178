import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { GlobalStyle } from "./app/layout/GlobalStyle";
import { useTranslation } from "react-i18next";
// import { useTranslatio } from "react-i18next";

const RootComponent = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language; // 현재 언어 가져오기

  return (
    <>
      <GlobalStyle language={currentLanguage} />
      <App />
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <RootComponent />
  // </React.StrictMode>
);
