import { useNavigate } from 'react-router-dom';
import { FooterWMSvg, InstarIconsBox, LinkText } from '../style/FooterStyle';
import { useURLStore } from '../../../store/url/UrlStore';
import { getLocaleText } from '../../../lib/util/LanguageUtil';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const currentLanguage = useURLStore((state) => state.language); 

    return (
   
        <div className="bg-gray-900">
            <div className="container item-center mx-auto py-8 px-4 max-w-6xl flex justify-between text-white">
                <div className="text-xs font-normal"> 
                    <div>
                    {t('footer_inc')}
                    </div>
                    <div>
                    {t('footer_address')}
                        <br/>{t('footer_detail_address')}
                    </div>
                    <div>
                        TEL 062-530-3470
                    </div>
                    <div>
                        FAX 062-530-3449
                    </div>
                    <div>
                        MAIL xrai.inc@gmail.com
                    </div>
                    <div>
                        <div className="flex">
                            <FooterWMSvg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="far"
                                data-icon="copyright"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                data-fa-i2svg=""
                                // className="w-5 h-5"
                            >
                                <path
                                    fill="currentColor"
                                    d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 448c-110.532 0-200-89.451-200-200 0-110.531 89.451-200 200-200 110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200zm107.351-101.064c-9.614 9.712-45.53 41.396-104.065 41.396-82.43 0-140.484-61.425-140.484-141.567 0-79.152 60.275-139.401 139.762-139.401 55.531 0 88.738 26.62 97.593 34.779a11.965 11.965 0 0 1 1.936 15.322l-18.155 28.113c-3.841 5.95-11.966 7.282-17.499 2.921-8.595-6.776-31.814-22.538-61.708-22.538-48.303 0-77.916 35.33-77.916 80.082 0 41.589 26.888 83.692 78.277 83.692 32.657 0 56.843-19.039 65.726-27.225 5.27-4.857 13.596-4.039 17.82 1.738l19.865 27.17a11.947 11.947 0 0 1-1.152 15.518z"
                                ></path>
                            </FooterWMSvg>
                            <div>
                            2025. XRAI Co.,Ltd. ALL RIGHTS RESERVED.
                            </div>
                        </div>
                    </div>
                    <div className='flex'>
                    <LinkText onClick={() => navigate(`/${currentLanguage}/service`)}>{t('terms_service')}</LinkText>
                    <LinkText onClick={() => navigate(`/${currentLanguage}/privacy`)}>{t('privacy_policy')}</LinkText>
                    <LinkText>
                    <a href={`https://xrai-api.com/index.php?mid=page_btLQ36&l=${currentLanguage}`}>{t('about_us')}</a></LinkText>
                    </div>
                    
                </div>
                <div className="flex items-center">
                    <InstarIconsBox>
                        <a href="https://www.instagram.com/p/DFCeTRbT3P1/?igsh=MWl2ZzRoeXRjNWc4Zg==">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" className="w-full h-full">
                                <path fill="currentColor" d="M295.42,6c-53.2,2.51-89.53,11-121.29,23.48-32.87,12.81-60.73,30-88.45,57.82S40.89,143,28.17,175.92c-12.31,31.83-20.65,68.19-23,121.42S2.3,367.68,2.56,503.46,3.42,656.26,6,709.6c2.54,53.19,11,89.51,23.48,121.28,12.83,32.87,30,60.72,57.83,88.45S143,964.09,176,976.83c31.8,12.29,68.17,20.67,121.39,23s70.35,2.87,206.09,2.61,152.83-.86,206.16-3.39S799.1,988,830.88,975.58c32.87-12.86,60.74-30,88.45-57.84S964.1,862,976.81,829.06c12.32-31.8,20.69-68.17,23-121.35,2.33-53.37,2.88-70.41,2.62-206.17s-.87-152.78-3.4-206.1-11-89.53-23.47-121.32c-12.85-32.87-30-60.7-57.82-88.45S862,40.87,829.07,28.19c-31.82-12.31-68.17-20.7-121.39-23S637.33,2.3,501.54,2.56,348.75,3.4,295.42,6m5.84,903.88c-48.75-2.12-75.22-10.22-92.86-17-23.36-9-40-19.88-57.58-37.29s-28.38-34.11-37.5-57.42c-6.85-17.64-15.1-44.08-17.38-92.83-2.48-52.69-3-68.51-3.29-202s.22-149.29,2.53-202c2.08-48.71,10.23-75.21,17-92.84,9-23.39,19.84-40,37.29-57.57s34.1-28.39,57.43-37.51c17.62-6.88,44.06-15.06,92.79-17.38,52.73-2.5,68.53-3,202-3.29s149.31.21,202.06,2.53c48.71,2.12,75.22,10.19,92.83,17,23.37,9,40,19.81,57.57,37.29s28.4,34.07,37.52,57.45c6.89,17.57,15.07,44,17.37,92.76,2.51,52.73,3.08,68.54,3.32,202s-.23,149.31-2.54,202c-2.13,48.75-10.21,75.23-17,92.89-9,23.35-19.85,40-37.31,57.56s-34.09,28.38-57.43,37.5c-17.6,6.87-44.07,15.07-92.76,17.39-52.73,2.48-68.53,3-202.05,3.29s-149.27-.25-202-2.53m407.6-674.61a60,60,0,1,0,59.88-60.1,60,60,0,0,0-59.88,60.1M245.77,503c.28,141.8,115.44,256.49,257.21,256.22S759.52,643.8,759.25,502,643.79,245.48,502,245.76,245.5,361.22,245.77,503m90.06-.18a166.67,166.67,0,1,1,167,166.34,166.65,166.65,0,0,1-167-166.34" transform="translate(-2.5 -2.5)"/>
                            </svg>
                        </a>
                    </InstarIconsBox>
                </div>
            </div>
        </div>
    );
}