import { create } from 'zustand';  // named import
import { persist } from 'zustand/middleware';  
// 1. concepts 데이터 관리용 store
export const useLoginStore = create(
    persist(
      (set) => ({
        mydata: {},
        setMydata: (newdata) => set({ mydata: newdata }),
        resetMyData: () => set({ mydata: {} }),
        setFirstLogin:(isFirstLogin) => 
          set((state) => ({
            mydata: {
              ...state.mydata,
              is_first_login: isFirstLogin,
            },
          })),
        setLoginReword:(loginReword) => 
        set((state) => ({
          mydata: {
            ...state.mydata,
            login_reword: loginReword
          },
        })),
        setThreeLeafClover:(clover)=>
          set((state) => ({
            mydata: {
              ...state.mydata,
              three_leaf_clover_amount: clover,
            },
          })),
        decrementThreeLeafClover: () => 
          set((state) => ({
            mydata: {
              ...state.mydata,
              three_leaf_clover_amount: Math.max(0, (state.mydata.three_leaf_clover_amount || 0) - 1),
            },
          })),
          
          decrementTenThreeLeafClover: (clover) => 
            set((state) => ({
              mydata: {
                ...state.mydata,
                three_leaf_clover_amount: Math.max(0, (state.mydata.three_leaf_clover_amount || 0) - clover),
              },
            })),
        plusThreeLeafClover:(clover)=>
          set((state) => ({
            mydata: {
              ...state.mydata,
              three_leaf_clover_amount: state.mydata.three_leaf_clover_amount + clover,
            },
          })),

        incrementClover: (four_leaf_amount,three_leaf_amount) => 
          
            set((state) => ({
              
              mydata: {
                ...state.mydata,
                three_leaf_clover_amount: state.mydata.three_leaf_clover_amount  + three_leaf_amount,
                four_leaf_clover_amount: state.mydata.four_leaf_clover_amount + four_leaf_amount,
              },
            })),
      }),
      {
        name: "mydata-storage", // 로컬스토리지의 키
        getStorage: () => localStorage,
      }
    )
  );