import styled from "styled-components";

export const FooterWMSvg = styled.svg`
  width: 10px;

  margin-right: 10px;
`;


export const InstarIconsBox = styled.div`
  width: 30px;
  height: 30px;
  color: white;
  // color: rgb(99 102 241 / var(--tw-text-opacity)); /* 기본 색상 */
  cursor: pointer;

  /* 색상 변화를 부드럽게 하기 위해 transition 추가 */
  transition: color 0.3s ease; /* 0.3초 동안 부드럽게 색상이 변하도록 설정 */

  &:hover {
    color: rgb(129 140 248 / var(--tw-text-opacity)); /* 호버 색상 */
  }
`;

export const LinkText = styled.div`
  cursor: pointer;
  margin-right: 10px;
  transition: color 0.2s ease-in-out; /* 부드러운 색상 변화 */

&:hover {
  color: gray; /* 호버 시 회색으로 변경 */
}
`