import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../lib/util/Cookie";
import { useLocation } from "react-router-dom";
import { TokenName,TokenExpire } from "../../lib/util/Common";
import api from "../../lib/api/apiConfig";
import { fetchMydata } from "../../lib/api/login/GetMyData";
import { useQuery } from "@tanstack/react-query";
import { useConceptsStore } from "../../store/home/HomeStore";
import { useLoginStore } from "../../store/login/LoginStore";
import { useURLStore } from "../../store/url/UrlStore";
import { useLoggingStore } from "../../store/logs/LogsStore";
import { LogsStatusAPI } from "../../lib/api/common/Resources";


// import { alertFormatDate } from "@/services/util/TransForm";
const GoogleRedirectURL = () => {
	const language = useURLStore((state) => state.language); 
	const setLoggingData = useLoggingStore((state) => state.setLoggingData);

	const navigate = useNavigate();
    const { data, isLoading, isError, error ,refetch } = useQuery({
        queryKey: ['mydata'], 
        queryFn: fetchMydata, 
        keepPreviousData: true, 
        enabled:false, // 이전 데이터를 유지하여 빠르게 화면을 갱신하도록 설정
      });
    const { setMydata,setFirstLogin, setLoginReword} = useLoginStore(); 
	const [firstLogin2,setFirstLogin2] = useState(false);
	const [isReword, setReword] = useState(null);
	useEffect(()=>{
		if (data){
			setMydata(data)
			setFirstLogin(firstLogin2)
			setLoginReword(isReword);
			navigate(`/${language}/`)
		}
	},[data])
	useEffect(() => {
		const fetchAuthData = async () => {			
			const error = new URL(window.location.href).searchParams.get("error");
			if (error){
				
				navigate(`/${language}/login`)
				return false
			}
			try {
				const code = new URL(window.location.href).searchParams.get("code");
				const state = new URL(window.location.href).searchParams.get("state");
				const response = await api.get(`/api/v1/auth/google/callback?code=${code}&state=${state}`);
				const logs  =  await LogsStatusAPI();

				const tokenData = response.data;
				if (response.status == 200) {
					setCookie(TokenName, tokenData.access_token, { path: "/", maxAge: TokenExpire });
                    refetch()
                    setMydata(data)
					setFirstLogin(tokenData.is_first_login)
					setFirstLogin2(tokenData.is_first_login)
					setReword(tokenData.login_reword)
					setLoggingData(logs.data)
                }
			} catch (err) {
				console.log(err);
				
				navigate(`/${language}/`);
				
			} finally {
				
			}
		};

		fetchAuthData();
	}, [navigate]);
    const mydata = useLoginStore((state) => state.mydata);

	if (isLoading) {
		return (
			<div>
				{/* <LoadingPage /> */}
			</div>
		);
	}


	return (
		<div>
			{/* <LoadingPage /> */}
		</div>
	);
};

export default GoogleRedirectURL;
