import { matchPath, useLocation, useNavigate } from "react-router-dom"
import { getCookie } from "../../lib/util/Cookie";
import { TokenName } from "../../lib/util/Common";
import { useEffect, useState } from "react";
import { useURLStore } from "../../store/url/UrlStore";
import i18n from "../../i18n";
import { LogURLAPI, ResourcesAPI } from "../../lib/api/common/Resources";
import { useLoginStore } from "../../store/login/LoginStore";
import { useLoggingStore } from "../../store/logs/LogsStore";


export const AuthGuard = ({children}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true); // 로딩 상태 추가
    const [token, setToken] = useState(getCookie(TokenName)); // 쿠키 상태 관리
    const language = useURLStore((state) => state.language); 
    const setLanguage = useURLStore((state) => state.setLanguage); 
    const setThreeLeafClover = useLoginStore((state) => state.setThreeLeafClover);
    const loggingData = useLoggingStore((state) => state.loggingData);
    const currentLanguage = i18n.language;
    
    useEffect(() => {
      if (loggingData.is_front_logging === true) {
        if (token) {
          LogURLAPI(location.pathname)
        }
      }
    },[location])
 

     // 사용자가 직접 언어 URL을 바꿀 경우 유효한 경우만 언어 설정 변경
     useEffect(() => {
        const pathSegments = location.pathname.split("/");
        const possibleLanguage = pathSegments[1];
        // 예외 경로 리스트
        const exceptionPaths = ["/google/callback", "/kakaoLogin", "/privacy", "/service"];
        // 예외 경로 처리
        if (exceptionPaths.includes(location.pathname)) {
          setLoading(false); // 로딩 상태를 false로 설정
          return;
        }
        // 유효한 언어 코드인지 확인
        if (["ko", "ko-KR", "en", "en-US","ja", "ja-JP", "th", "th-TH"].includes(possibleLanguage)) {
          const languagePrefix = possibleLanguage.split("-")[0];
          setLanguage(languagePrefix);
          i18n.changeLanguage(languagePrefix); // i18next 언어도 변경
        } else if (pathSegments[1]) {
          // 잘못된 언어 코드 입력 시 NotFoundPage로 리다이렉트
          navigate(`/${language}/*`, { replace: true });
        }
      }, [location, setLanguage, navigate, language]);


// "/" URL 입력에 대한 처리
  useEffect(() => {
    if (location.pathname === "/") {
      navigate(`/${language}/`, { replace: true });
      setLoading(false); 
    }
  }, [location, navigate, language]);

    // 항상 접근이 가능한 페이지
    const alwaysPages = [ `/${language}/paymentComplete`, "/privacy", "/service",`/${language}`,];
    // 로그인시 접근이 가능한 페이지
    const yesTokenPage = [ `/${language}/concepts/:id`, `/${language}/Product`, `/${language}/mohaStore`, `/${language}/detail`, `/${language}/payment`    ];
    // 로그인이 아닐시 접근이 가능한 페이지
    const noTokenPage = ["/google/callback", "/kakaoLogin", `/${language}/login`];



    const isPathMatching = (pathList) => pathList.some((path) => matchPath(path, location.pathname));
    useEffect(() => {
        const token = getCookie(TokenName); 

        if (!token && isPathMatching(yesTokenPage)) {
            navigate(`/${language}/login`, { replace: true });
            return;
        }

        if (token && isPathMatching(noTokenPage)) {
            navigate(`/${language}/`, { replace: true });
            return;
        }

        setToken(token); 
        setLoading(false);
    }, [location.pathname, navigate]); 

    useEffect(() => {
      const fetchResources = async () => {
        if (token && isPathMatching(yesTokenPage)) {
          try {
            const resp = await ResourcesAPI();
            // 여기서 추가적인 작업을 진행할 수 있습니다.
            setThreeLeafClover(resp.data.three_leaf_clover)
          } catch (error) {
            console.error("API 호출 실패:", error);
          }
        }
      };
    
      fetchResources(); // async 함수 호출
    }, [token, location.pathname]); // 의존성 배열에 token과 location.pathname 추가
    if (loading) {
        return null
    }
    
  

    return <>{children}</>
    
}