import styled, { createGlobalStyle } from "styled-components";
import BMJUAOTF from "../../assets/font/BMJUA_otf.otf";
import BMJUATTF from "../../assets/font/BMJUA_ttf.ttf";

export const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'BMJUA';
    src: url(${BMJUAOTF}) format('opentype'),
         url(${BMJUATTF}) format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* 기본 폰트 설정 */
@font-face {
    font-family: 'DefaultFont';
    src: local('Arial'), local('sans-serif'); /* 기본 폰트 폴백 */
}

/* 언어별 폰트 적용 */
body {
    font-family: ${(props) =>
      props.language === "ko" || props.language === "en"
        ? "'BMJUA', 'DefaultFont', sans-serif"
        : "system-ui, sans-serif"};
}
`;


export const StyledText = styled.div`
  font-family: "BMJUA", sans-serif !important;
`;

export const StyledTextH5 = styled.h5`
  font-family: "BMJUA", sans-serif !important;
`;
export const StyledTextH4 = styled.h4`
  font-family: "BMJUA", sans-serif !important;
`;


