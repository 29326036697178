import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { AiFillHome } from "react-icons/ai";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { IoStorefront } from "react-icons/io5";
import { IoLogIn } from "react-icons/io5";
import { IoLogOutOutline } from "react-icons/io5";
import { TbListSearch } from "react-icons/tb";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from "../../../assets/img/logo-1.png";
import threeLeafClover from "../../../assets/img/clover.png"
import clover from "../../../assets/img/pngwing.com (10).png"
import { getCookie, removeCookie } from '../../../lib/util/Cookie';
import { TokenName } from '../../../lib/util/Common';
import { useLoginStore } from '../../../store/login/LoginStore';
import { useQueryClient } from '@tanstack/react-query';
import defaultImg from "../../../assets/img/favicon.png"
import { useURLStore } from '../../../store/url/UrlStore';
import { getLocaleText } from '../../../lib/util/LanguageUtil';
import { useTranslation } from 'react-i18next';
import { useLoggingStore } from '../../../store/logs/LogsStore';



export default function LoginHeader({type}) {
  const resetMyData = useLoginStore((state) => state.resetMyData);
  const language = useURLStore((state) => state.language); 
  const queryClient = useQueryClient();
  const myData = useLoginStore((state) => state.mydata); 
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setLogin] = useState(false);
  const { t } = useTranslation();
  const resetLoggingData = useLoggingStore((state) => state.resetLoggingData);

  const navigation = [
    { name: t('home') , href: `/${language}/`, icon: AiFillHome },
    { name: t('my_creations'), href: `/${language}/product`, icon: MdOutlineProductionQuantityLimits },
    { name: t('store'), href: `/${language}/mohaStore`, icon: IoStorefront },
    { name: t('orders'), href: `/${language}/detail`, icon: TbListSearch },
  ];

  const navigationLogin = [
    { name: t('home'), href: `/${language}/`, icon: AiFillHome },
  ];

  

  const isCurrentPath = (path) => {
    return location.pathname === path;
  };

  const clearQueryData = () => {
    queryClient.invalidateQueries(['mydata']);
  };

  useEffect(() => {
    const token = getCookie(TokenName);
    setLogin(!!token);
  }, [isLogin, navigate]);

  const handleLogout = () => {
    clearQueryData();
    resetMyData(); // Zustand 상태 초기화
    resetLoggingData();
    removeCookie(TokenName);
    localStorage.removeItem("mydata-storage");
    removeCookie(TokenName);
    setLogin(false);
    navigate(`/${language}/login`);
  };

  const handleLogin = () => {
    navigate(`/${language}/login`);
  };

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-md shadow-sm">
      <nav className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          {/* Mobile menu button */}
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-lg p-2.5 text-gray-700 hover:bg-gray-100/50 transition-all duration-200"
            >
              <span className="sr-only">{t('menu_open')}</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          {/* Logo - 데스크톱 */}
          <div className="hidden lg:flex lg:flex-1">
            <Link to="/" className="flex items-center">
              <img src={logo} alt="MOHA Logo" className="h-8 w-auto" />
            </Link>
          </div>

          {/* Desktop navigation */}
          <div className="hidden lg:flex lg:gap-x-8">
            {(isLogin ? navigation : navigationLogin).map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={`relative inline-flex items-center px-2 py-1 text-sm font-medium transition-all duration-200
                  ${isCurrentPath(item.href) 
                    ? 'text-[#F68063]' 
                    : 'text-gray-700 hover:text-[#F68063]'}`}
              >
                {item.name}
                {isCurrentPath(item.href) && (
                  <span className="absolute inset-x-0 -bottom-px h-0.5 bg-[#F68063]" />
                )}
              </Link>
            ))}
          </div>

          {/* Login area - Desktop */}
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {isLogin ? (
              <div className="flex items-center space-x-4">
                {/* Currency Display */}
                <div className="flex items-center space-x-2 bg-gray-50 rounded-lg p-2">
                  {/* Three Leaf Clover */}
                  <div className="flex items-center px-3 py-1.5 bg-white rounded-md border border-gray-100 hover:border-[#F68063] transition-all duration-200">
                    <img
                      src={threeLeafClover}
                      className="w-4 h-4 mr-2"
                      alt="Three Leaf Clover"
                    />
                    <span className="text-sm font-medium text-gray-700">
                      {myData?.three_leaf_clover_amount || 0}
                    </span>
                  </div>
                  
                  {/* Four Leaf Clover */}
                  {/* <div className="flex items-center px-3 py-1.5 bg-white rounded-md border border-gray-100 hover:border-[#F68063] transition-all duration-200">
                    <img
                      src={clover}
                      className="w-4 h-4 mr-2"
                      alt="Four Leaf Clover"
                    />
                    <span className="text-sm font-medium text-gray-700">
                      {myData?.four_leaf_clover_amount || 0}
                    </span>
                  </div> */}
                </div>

                {/* Profile Picture */}
                <div className="relative group">
                  <div className="w-10 h-10 rounded-full overflow-hidden ring-2 ring-gray-100 transition-all duration-200 group-hover:ring-[#F68063]">
                    <img
                      src={defaultImg}
                      className="w-full h-full object-cover"
                      alt="Profile"
                    />
                  </div>
                  <div className="absolute -bottom-1 -right-1 w-3 h-3 bg-green-400 rounded-full border-2 border-white"></div>
                </div>

                {/* Logout Button */}
                <button
                  onClick={handleLogout}
                  className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium text-gray-700 bg-white border border-gray-200 hover:bg-gray-50 hover:border-[#F68063] hover:text-[#F68063] transition-all duration-200"
                >
                  <IoLogOutOutline className="w-4 h-4 mr-2" />
                  {t('logout')}
                </button>
              </div>
            ) : (
              <button
                onClick={handleLogin}
                className="group relative inline-flex items-center gap-x-2 rounded-lg bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-200 hover:bg-gray-50 hover:ring-[#F68063] transition-all duration-200"
              >
                <IoLogIn className="h-4 w-4 text-gray-500 group-hover:text-[#F68063]" />
                {t('login')}
              </button>
            )}
          </div>

          {/* Mobile logo center */}
          <div className="lg:hidden flex flex-1 justify-center">
            <Link to="/">
              <img src={logo} alt="MOHA Logo" className="h-8 w-auto" />
            </Link>
          </div>

          {/* Mobile button - right side */}
          <div className="flex lg:hidden">
            {isLogin ? (
              <div className="relative group">
                <div className="w-8 h-8 rounded-full overflow-hidden ring-2 ring-gray-100">
                  <img
                    src={defaultImg}
                    className="w-full h-full object-cover"
                    alt="Profile"
                    onClick={() => setMobileMenuOpen(true)}
                  />
                </div>
                <div className="absolute -bottom-1 -right-1 w-2 h-2 bg-green-400 rounded-full border-2 border-white"></div>
              </div>
            ) : (
              <Link
                to={`/${language}/login`}
                className="inline-flex items-center gap-x-1 text-sm font-semibold text-gray-900"
              >
                <IoLogIn className="h-5 w-5" />
                <span className="sr-only">{t('login')}</span>
              </Link>
            )}
          </div>
        </div>
      </nav>

      {/* Mobile menu */}
      <Dialog 
        as="div" 
        className="lg:hidden" 
        open={mobileMenuOpen} 
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 bg-black/20 backdrop-blur-sm" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to={`/${language}/`} className="-m-1.5 p-1.5">
              <img src={logo} alt="MOHA Logo" className="h-8 w-auto" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700 hover:bg-gray-100/50 transition-all duration-200"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">{t('menu_close')}</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          {/* Mobile Profile Section */}
          {isLogin && (
            <div className="mt-6 mb-4">
              <div className="flex items-center p-3 bg-gray-50 rounded-xl">
                <div className="flex flex-1 items-center space-x-2">
                  {/* Currency Display - Mobile */}
                  <div className="flex items-center px-2 py-1 bg-white rounded-md border border-gray-100">
                    <img src={threeLeafClover} className="w-3 h-3 mr-1" alt="Three Leaf" />
                    <span className="text-xs font-medium text-gray-700">
                      {myData?.three_leaf_clover_amount || 0}
                    </span>
                  </div>
                  {/* <div className="flex items-center px-2 py-1 bg-white rounded-md border border-gray-100">
                    <img src={clover} className="w-3 h-3 mr-1" alt="Four Leaf" />
                    <span className="text-xs font-medium text-gray-700">
                      {myData?.four_leaf_clover_amount || 0}
                    </span>
                  </div> */}
                </div>
                
                {/* Profile Picture - Mobile */}
                <div className="relative">
                  <div className="w-10 h-10 rounded-full overflow-hidden ring-2 ring-gray-100">
                    <img
                      src={defaultImg}
                      className="w-full h-full object-cover"
                      alt="Profile"
                    />
                  </div>
                  <div className="absolute -bottom-1 -right-1 w-3 h-3 bg-green-400 rounded-full border-2 border-white"></div>
                </div>
              </div>
            </div>
          )}

          {/* Navigation Menu */}
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {(isLogin ? navigation : navigationLogin).map((item) => {
                  const Icon = item.icon;
                  return (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={`group -mx-3 flex items-center gap-x-3 rounded-lg px-3 py-2 text-base font-semibold transition-all duration-200
                        ${isCurrentPath(item.href)
                          ? 'text-[#F68063] bg-orange-50'
                          : 'text-gray-900 hover:bg-gray-50'}`}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <Icon className={`h-6 w-6 ${isCurrentPath(item.href) ? 'text-[#F68063]' : 'text-gray-400 group-hover:text-[#F68063]'}`} />
                      {item.name}
                    </Link>
                  );
                })}
              </div>
              
              {/* Login/Logout Button */}
              <div className="py-6">
                {isLogin ? (
                  <button
                    onClick={() => {
                      handleLogout();
                      setMobileMenuOpen(false);
                    }}
                    className="flex w-full items-center justify-center gap-x-2 rounded-lg bg-white px-4 py-3 text-sm font-semibold text-gray-700 border border-gray-200 hover:bg-gray-50 hover:border-[#F68063] hover:text-[#F68063] transition-all duration-200"
                  >
                    <IoLogOutOutline className="h-5 w-5" />
                    {t('logout')}
                  </button>
                ) : (
                  <Link
                    to={`/${language}/login`}
                    className="flex w-full items-center justify-center gap-x-2 rounded-lg bg-[#F68063] px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-[#FFAA77] transition-all duration-200"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <IoLogIn className="h-5 w-5" />
                    {t('login')}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}