import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginHeader from "./app/home/components/LoginHeader";
import { ClipLoader } from 'react-spinners';
import GoogleRedirectURL from "./app/login/GoogleLogin";
import KakaoRedirectURL from "./app/login/KakaoLogin";
import { Payment } from "./app/payment/Payment";
import { AuthGuard } from "./components/auth/AuthGuard";
import { useURLStore } from "./store/url/UrlStore";
import { PrivacyPolicyPage } from "./app/policy/PrivacyPolicy";
import { TermsOfServicePage } from "./app/policy/TermsOfService";
import { SuspenseFallback } from "./lib/util/SuspenseLoading";



// Lazy loaded components
const HomePage = lazy(() => import("./app/home/HomePage"));
const ConceptPage = lazy(() => import("./app/concepts/ConceptPage"));
const LoginPage = lazy(() => import("./app/login/LoginPage"));
const ProductPage = lazy(() => import("./app/product/ProductPage"));
const MohaStorePage = lazy(() => import("./app/mohaStore/MohaStorePage"));
const PurchaseDetailsPage = lazy(() => import("./app/purchaseDetails/PurchaseDetailsPage"))
const PaymentComplete = lazy(() => import("./app/mohaStore/PaymentComplete"));
const NotFoundPage = lazy(() => import("./app/notFound/NotFoundPage"));
const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-50 pt-16">
      {/* <LoginHeader /> */}
        {children}
    </div>
  );
};

const queryClient = new QueryClient();

function App() {
  const language = useURLStore((state) => state.language); 
  
  useEffect(() => {
    switch (language) {
      case "ko":
        document.title = "모하 AI - 나만의 미니미, AI 프로필";
        break;
      case "en":
        document.title = "MOHA AI - Transform Photos into Personal AI Avatars";
        break;
      case "ja":
        document.title = "MOHA AI - 写真を個人のAIアバターに変換する";
        break;
      case "th":
        document.title = "MOHA AI - แปลงภาพถ่ายเป็นอวาตาร์ AI ส่วนตัว";
        break;
      default:
        document.title = "MOHA AI - Transform Photos into Personal AI Avatars";
    }
  }, [language]);

  return (
   
    <QueryClientProvider client={queryClient}>
      <Router>
        {/* 전역 Suspense를 여기에 배치 */}
        <Suspense fallback={<SuspenseFallback/>}>
          <Routes>
          <Route path={"/google/callback"} element={<Layout><GoogleRedirectURL /></Layout>} ></Route>
            <Route path={"/kakaoLogin"} element={<Layout><KakaoRedirectURL /></Layout>} ></Route>
           
          </Routes>
          <AuthGuard>
          <Routes>
            <Route path={`/:${language}/test`} element={<Layout><HomePage /></Layout>} />            
            <Route path={`/:${language}/Ceazer`} element={<Layout><LoginPage /></Layout>} />
            <Route path={`/:${language}/`} element={<Layout><HomePage /></Layout>} />
            <Route path={`/:${language}/concepts/:id`} element={<Layout><ConceptPage /></Layout>} />
            <Route path={`/:${language}/login`} element={<Layout><LoginPage /></Layout>} />
            <Route path={`/:${language}/Product`} element={<Layout><ProductPage /></Layout>} />
            <Route path={`/:${language}/mohaStore`} element={<Layout><MohaStorePage /></Layout>} />
            <Route path={`/:${language}/detail`} element={<Layout><PurchaseDetailsPage /></Layout>} />
             <Route path={"/privacy"} element={<Layout><PrivacyPolicyPage/></Layout>} ></Route>
            <Route path={"/service"} element={<Layout><TermsOfServicePage/></Layout>} ></Route>
            <Route path={`/:${language}/paymentComplete`} element={<Layout><PaymentComplete /></Layout>} />
            <Route path={`/:${language}/privacy`} element={<Layout><PrivacyPolicyPage/></Layout>} ></Route>
              <Route path={`/:${language}/service`} element={<Layout><TermsOfServicePage/></Layout>} ></Route>
            {/* <Route path={`/:${language}/loading`} element={<SuspenseFallback />}/> */}
            {/* Payment 페이지는 별도의 레이아웃을 가지므로 따로 Suspense 처리 */}
            <Route 
              path={"/payment"} 
              element={
                <Suspense fallback={<ClipLoader />}>
                  <Payment />
                </Suspense>
              } 
            />
              <Route path={`/:${language}/paymentComplete`} element={<Layout><PaymentComplete /></Layout>} ></Route>
              <Route path={`/:${language}/*`} element={<Layout><NotFoundPage/></Layout>} ></Route>
            </Routes>

            </AuthGuard>
        </Suspense>
      </Router>
      <ReactQueryDevtools />
    </QueryClientProvider>
 
  );
}
export default App;